import { InMemoryCache, split, ApolloClient } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import userStore from 'src/stores/user';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocket } from 'ws';

const httpLink = new BatchHttpLink({
	uri: import.meta.env.VITE_HASURA_HTTP_URI
});

const wsLink = new GraphQLWsLink(
	createClient({
		url: import.meta.env.VITE_HASURA_WS_URI,
		webSocketImpl: WebSocket
	})
);

const authLink = setContext(async ({ query }, { headers, connectionParams }) => {
	const token = await userStore.getAccessToken();

	if (token) {
		const definition = getMainDefinition(query);
		if (definition.kind === 'OperationDefinition' && definition.operation === 'subscription') {
			return {
				connectionParams: {
					...connectionParams,
					headers: { ...connectionParams?.headers, Authorization: `Bearer ${token}` }
				}
			};
		} else {
			return {
				headers: { ...headers, Authorization: `Bearer ${token}` }
			};
		}
	} else {
		return { headers: { ...headers }, connectionParams: { ...connectionParams } };
	}
});

const link = authLink.concat(
	split(
		({ query }) => {
			const definition = getMainDefinition(query);
			return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
		},
		wsLink,
		httpLink
	)
);

const client = new ApolloClient({
	link,
	cache: new InMemoryCache()
});

export { client };
export default client;
