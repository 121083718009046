import auth0Service from 'src/lib/auth0-service';
import {
	DELETE_TAGS_OF_TEAM,
	DELETE_TEAM_MEMBER,
	GET_GROUPS_OF_TEAM,
	GET_TAGS,
	GET_TEAMS_OF_USER,
	GET_TEAM_CORE_BY_ID,
	GET_TEAM_INVITES_OF_USER,
	GET_USER_CORE_BY_ID,
	INSERT_TAGS_TO_TEAM,
	INSERT_TEAM_NAME,
	INSERT_USER_NAME,
	RESPOND_TEAM_INVITATION,
	UPDATE_SOCIAL_MEDIA_OF_TEAM,
	UPDATE_TEAM,
	UPDATE_USER_OCCUPATION,
	UPLOAD_PROFILE_PICTURE
} from 'src/lib/apollo/queries';
import { goto } from '$app/navigation';
import type { User } from '@auth0/auth0-spa-js';
import { browser } from '$app/env';
import { query, mutation, setClient } from 'svelte-apollo';
import { client } from 'src/lib/apollo/client-provider';
import Swal from 'sweetalert2';

/**
 * Acts as a facade to Auth0Service and session.
 * Contains both auth0User and hasuraUser.
 */

interface UserProps {
	cpf: string;
	created_at: string;
	email: string;
	id: string;
	names: [{ name: string }];
	occupation: string;
	profile_picture: { path: string };
	role: string;
	social_media: { facebook: string; instagram: string; linkedin: string; other: string };
	updated_at: string;
}
interface UserData {
	users: UserProps[];
}
class UserStore {
	private async getHasuraUser() {
		const auth0 = await auth0Service;
		if (await auth0.isAuthenticated) {
			const user = await query<UserData>(GET_USER_CORE_BY_ID, {
				variables: { id: (await auth0.user).sub }
			}).result();
			if (user.error || user.errors) {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Algo de errado aconteceu',
					footer: '<a href="">Porque estou tendo este erro?</a>'
				});
				await this.logout();
				return null;
			}

			return user.data?.users[0];
		}
		return null;
	}

	public async loginWithPopup(redirectUrl = '') {
		const auth0 = await auth0Service;
		await auth0.loginWithPopup();
		if (await auth0.isAuthenticated) {
			setClient(client);
			await this.getHasuraUser(); // make request and store in cache
			if (redirectUrl) return await goto(redirectUrl);
			return location.reload();
		}
	}

	public async logout() {
		await (await auth0Service).logout();
		localStorage.removeItem('team_id');
	}

	public async getProject(teamId?: string): Promise<object> {
		if (!teamId) teamId = this.getSelectedProjectId();
		const team = await query(GET_TEAM_CORE_BY_ID, {
			variables: { id: teamId }
		}).result();
		if (team.error || team.errors) {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Algo de errado aconteceu',
				footer: '<a href="">Porque estou tendo este erro?</a>'
			});
			return null;
		}
		return team.data?.teams_by_pk;
	}

	public async getAllUserProjects(): Promise<Array<object>> {
		const auth0 = await auth0Service;
		if (await auth0.isAuthenticated) {
			const team = await query(GET_TEAMS_OF_USER, {
				variables: { user_id: (await auth0.user).sub }
			}).result();
			if (team.error || team.errors) {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Algo de errado aconteceu',
					footer: '<a href="">Porque estou tendo este erro?</a>'
				});
				return null;
			}
			return team.data?.teams;
		}
		return null;
	}

	public async getUserPendingProjectsInvites(): Promise<Array<object>> {
		const auth0 = await auth0Service;
		if (await auth0.isAuthenticated) {
			const invites = await query(GET_TEAM_INVITES_OF_USER, {
				variables: { user_id: (await auth0.user).sub }
			}).result();
			if (invites.error || invites.errors) {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Algo de errado aconteceu',
					footer: '<a href="">Porque estou tendo este erro?</a>'
				});
				return null;
			}
			return invites.data?.team_invites;
		}
		return null;
	}

	public async getUserTeamGroups(teamId?: string): Promise<Array<object>> {
		if (!teamId) teamId = this.getSelectedProjectId();
		const groups = await query(GET_GROUPS_OF_TEAM, {
			variables: { team_id: teamId }
		}).result();
		if (groups.error || groups.errors) {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Algo de errado aconteceu',
				footer: '<a href="">Porque estou tendo este erro?</a>'
			});
			return null;
		}
		return groups.data?.groups;
	}

	public async respondInvitation(invite_id: string, accepted: boolean): Promise<void> {
		const respondTeamInvite = mutation(RESPOND_TEAM_INVITATION);
		await respondTeamInvite({
			variables: { invite_id: invite_id, accept: accepted }
		});
	}

	public async updateUser(user: {
		image: string;
		name: string;
		occupation: string;
	}): Promise<void> {
		const oldUser = await this.getUser();

		const uploadProfilePicture = mutation(UPLOAD_PROFILE_PICTURE);
		const insertUserName = mutation(INSERT_USER_NAME);
		const updateUserOccupation = mutation(UPDATE_USER_OCCUPATION);

		if (user.image)
			await uploadProfilePicture({
				variables: { user_id: oldUser.id, b64_img: user.image }
			});

		if (user.name != oldUser.names[0]?.name)
			await insertUserName({
				variables: { user_id: oldUser.id, name: user.name }
			});

		if (user.occupation != oldUser.occupation)
			await updateUserOccupation({
				variables: { user_id: oldUser.id, occupation: user.occupation }
			});
	}

	public async updateTeam(team: {
		cnpj: string;
		name: string;
		description: string;
		removedMembers: string[];
		socialMedias: {
			facebook: string;
			twitter: string;
			instagram: string;
			youtube: string;
			linkedin: string;
			other: string;
		};
		tags: string[];
	}): Promise<void> {
		const oldTeam = await this.getProject();

		const updateTeam = mutation(UPDATE_TEAM);
		const insertTeamName = mutation(INSERT_TEAM_NAME);
		const deleteTeamMember = mutation(DELETE_TEAM_MEMBER);
		const updateSocialMedia = mutation(UPDATE_SOCIAL_MEDIA_OF_TEAM);
		const deleteTagsOfTeam = mutation(DELETE_TAGS_OF_TEAM);
		const insertTagsToTeam = mutation(INSERT_TAGS_TO_TEAM);

		if (team.cnpj != oldTeam.cnpj || team.description != oldTeam.description)
			await updateTeam({
				variables: { team_id: oldTeam.id, cnpj: team.cnpj, description: team.description }
			});

		if (team.name != oldTeam.names[0]?.name)
			await insertTeamName({
				variables: { team_id: oldTeam.id, name: team.name }
			});

		for (const member_id of team.removedMembers)
			await deleteTeamMember({
				variables: { team_id: oldTeam.id, user_id: member_id }
			});

		const oldSocialMediasVals = Object.values(oldTeam?.social_media || {})
			.sort()
			.filter((i) => i !== 'social_medias');
		const socialMediasVals = Object.values(team?.socialMedias).sort();
		if (JSON.stringify(oldSocialMediasVals) != JSON.stringify(socialMediasVals))
			await updateSocialMedia({
				variables: { team_id: oldTeam.id, ...team?.socialMedias }
			});

		const oldTags = oldTeam?.tags.map((t) => t.tag.value).sort();
		team.tags = team.tags.sort();
		if (JSON.stringify(oldTags) != JSON.stringify(team.tags)) {
			await deleteTagsOfTeam({
				variables: { team_id: oldTeam.id }
			});

			const allTags = {};
			[...(await query(GET_TAGS).result()).data?.tags].forEach((t) => (allTags[t.value] = t.id));

			const teams_tags_insert_input = [
				...team.tags.map((val) => {
					if (allTags[val]) return { team_id: oldTeam.id, tag_id: allTags[val] };
					return { tag: { data: { value: val } }, team_id: oldTeam.id };
				})
			];

			await insertTagsToTeam({
				variables: { objects: teams_tags_insert_input }
			});
		}
	}

	/**
	 * All this information stays in cache.
	 */
	public async isAuthenticated(): Promise<boolean> {
		return await (
			await auth0Service
		).isAuthenticated;
	}

	public async getAccessToken(): Promise<string> {
		if (await this.isAuthenticated()) return await (await auth0Service).accessToken;
		return '';
	}

	public async getAuth0User(): Promise<User> {
		return await (
			await auth0Service
		).user;
	}

	public async getUser() {
		return await this.getHasuraUser();
	}

	public setSelectedProject(team_id: string): void {
		localStorage.setItem('team_id', team_id);
	}

	public getSelectedProjectId(): string {
		if (browser) return localStorage.getItem('team_id');
	}
}

export class UserRoles {
	public static ADMIN = 'admin';
	public static EDITOR = 'editor';
	public static MANAGER = 'manager';
	public static MENTOR = 'mentor';
	public static USER = 'user';
}

const userStore = new UserStore();
export default userStore;
